import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/styles.scss"
import Seo from "../components/Seo"
import Img from "gatsby-image"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlog(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMMM Do, YYYY")
            snippet
            thumbnail {
              fluid(maxWidth: 300) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Blog"
        description=" Read our blog to keep up to date with all the latest news for The
            Band Academy. This is also a place to learn songs and techniques on
            the guitar and bass as well as ideas for creativity and songwriting."
      />

      <div className="blogPageContainer">
        <h1>The Band Academy Blog</h1>
        <div className="readBlogWrap">
          <p className="readBlog">
            Read our blog to keep up to date with all the latest news for The
            Band Academy. This is also a place to learn songs and techniques on
            the guitar and bass as well as ideas for creativity and songwriting.
          </p>
        </div>
        <div className="blogPageWrap">
          <ol>
            {data.allContentfulBlog.edges.map(edge => {
              return (
                <li>
                  <Link to={`/blog/${edge.node.slug}`}>
                    <Img
                      width="250"
                      height="167"
                      fluid={edge.node.thumbnail.fluid}
                    />
                    <h2>{edge.node.title}</h2>
                    <p>{edge.node.publishedDate}</p>
                    <p>{edge.node.snippet}</p>
                  </Link>
                </li>
              )
            })}
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPage
